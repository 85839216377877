@import url(https://unpkg.com/mockman-js@latest/dist/style.css);
.video-listing-section {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin: 2rem auto 0 auto;
	max-width: 1560px;
}

.video-card {
	position: relative;
	overflow: hidden;
	margin: 0 2rem 2rem 0;
	min-width: 24rem;
	max-width: 29rem;
	background-color: var(--colour-black-fifth);
	border-radius: 5px;
	cursor: pointer;
	box-shadow: 0 2px 5px var(--colour-dark-with-opacity);
	-webkit-perspective: 400px;
	        perspective: 400px;
	opacity: 0;
	-webkit-transform: scale(0.5);
	        transform: scale(0.5);
	-webkit-animation-name: card-animation;
	        animation-name: card-animation;
	-webkit-animation-duration: 350ms;
	        animation-duration: 350ms;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}

@-webkit-keyframes card-animation {
	0% {
		opacity: 0;
		-webkit-transform: scale(0.8);
		        transform: scale(0.8);
	}
	50% {
		opacity: 0.8;
		-webkit-transform: scale(1.04);
		        transform: scale(1.04);
	}
	100% {
		opacity: 1;
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}

@keyframes card-animation {
	0% {
		opacity: 0;
		-webkit-transform: scale(0.8);
		        transform: scale(0.8);
	}
	50% {
		opacity: 0.8;
		-webkit-transform: scale(1.04);
		        transform: scale(1.04);
	}
	100% {
		opacity: 1;
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}

.video-card:hover {
	box-shadow: 0 5px 1rem var(--colour-dark-with-opacity);
}

.video-card .video-card-watch-later {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	right: 0;
	padding: 5px;
	background-color: var(--colour-black-fifth);
	color: var(--colour-text-white);
	-webkit-transform-origin: right;
	        transform-origin: right;
	-webkit-transform: rotateY(-120deg);
	        transform: rotateY(-120deg);
	transition: 200ms -webkit-transform ease-in-out;
	transition: 200ms transform ease-in-out;
	transition: 200ms transform ease-in-out, 200ms -webkit-transform ease-in-out;
}

.video-card:hover .video-card-watch-later {
	-webkit-transform: rotateY(0deg);
	        transform: rotateY(0deg);
}

.video-card-watch-later:hover {
	color: var(--colour-accent-one);
}
.video-card .video-card-in-watch-later {
	-webkit-transform: rotateY(0deg);
	        transform: rotateY(0deg);
	color: var(--colour-accent-one);
}

.video-card .video-card-add-to-playlist {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 3rem;
	right: 0;
	padding: 5px;
	background-color: var(--colour-black-fifth);
	color: var(--colour-text-white);
	-webkit-transform-origin: right;
	        transform-origin: right;
	-webkit-transform: rotateY(-120deg);
	        transform: rotateY(-120deg);
	transition: 400ms -webkit-transform ease-in-out;
	transition: 400ms transform ease-in-out;
	transition: 400ms transform ease-in-out, 400ms -webkit-transform ease-in-out;
}

.video-card:hover .video-card-add-to-playlist {
	-webkit-transform: rotateY(0deg);
	        transform: rotateY(0deg);
}

.video-card-add-to-playlist:hover {
	color: var(--colour-accent-one);
}

.video-card .video-card-in-playlist {
	-webkit-transform: rotateY(0deg);
	        transform: rotateY(0deg);
	color: var(--colour-accent-one);
}

.video-card .video-card-delete-button {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 1rem 0 0 1rem;
	top: 12rem;
	right: 0;
	padding: 5px;
	background-color: var(--colour-error);
	color: var(--both-theme-white);
	-webkit-transform-origin: right;
	        transform-origin: right;
	-webkit-transform: rotateY(-120deg);
	        transform: rotateY(-120deg);
	transition: 300ms -webkit-transform ease-in-out;
	transition: 300ms transform ease-in-out;
	transition: 300ms transform ease-in-out, 300ms -webkit-transform ease-in-out;
}

.video-card:hover .video-card-delete-button {
	-webkit-transform: rotateY(0deg);
	        transform: rotateY(0deg);
}

.video-card-title {
	font-size: 2rem;
	text-align: center;
	font-weight: bold;
	line-height: 1.5;
	margin: 1rem 1rem 1rem 5px;
	text-overflow: ellipsis;
	text-align: left;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	white-space: normal;
}

.video-card-creator {
	margin: 0 0 0 1rem;
}

.video-card-podcast {
	margin: 0 1rem 0 0;
}

.video-card img {
	margin: 0 4rem 0 0;
	width: 31rem;
	height: 16rem;
	object-fit: cover;
}

@media screen and (max-width: 1719px) {
	.video-listing-section {
		max-width: 1240px;
	}
}

@media screen and (max-width: 1375px) {
	.video-listing-section {
		max-width: 940px;
	}
}

@media screen and (max-width: 1031px) {
	.video-listing-section {
		max-width: 620px;
	}
}

@media screen and (max-width: 768px) {
	.video-listing-section {
		justify-content: center;
	}

	.video-card {
		margin: 2rem;
		max-width: 30rem;
		padding: 0;
	}

	.video-card .video-card-watch-later {
		-webkit-transform: rotateY(0deg);
		        transform: rotateY(0deg);
		min-height: 4rem;
		min-width: 4rem;
	}

	.video-card .video-card-add-to-playlist {
		-webkit-transform: rotateY(0deg);
		        transform: rotateY(0deg);
		min-height: 4rem;
		min-width: 4rem;
	}

	.video-card .video-card-delete-button {
		-webkit-transform: rotateY(0deg);
		        transform: rotateY(0deg);
		min-height: 4rem;
		min-width: 4rem;
		top: 11rem;
	}
}

:root {
	--z-index-modal-level: 10;
	--z-index-alert-level: 11;
	--z-index-profile-modal-level: 9;
	--z-index-card-level-2: 8;
	--z-index-card-level-1: 7;
	--z-index-card-level-0: 6;
	--z-index-low-level-1: 5;
	--z-index-low-level-2: 4;
}
.game-plex-app {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 8rem 1fr 5rem;
	grid-template-areas:
		'navbar'
		'main-content'
		'footer';
	margin: auto;
	min-height: 100vh;
	max-height: 100%;
	width: 100%;
}

.modal-opened {
	z-index: 10;
	z-index: var(--z-index-modal-level);
}

.app-main-content {
	position: relative;
	grid-area: main-content;
}

.app-footer {
	display: flex;
	justify-content: center;
	align-items: center;
	grid-area: footer;
}

.btn-link {
	cursor: pointer;
}

.width-100 {
	width: 100%;
}

.not-selectable {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.btn-fab {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5rem;
	border: 1px solid transparent;
	padding: 1rem;
	cursor: pointer;
	aspect-ratio: unset;
}

@media screen and (max-width: 768px) {
	.btn-fab > p {
		display: none;
	}
}

.navbar {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.navbar-brand {
	margin: 0 2rem;
	max-width: 100%;
	color: var(--colour-text-white);
	font-size: calc(2.2rem + 0.9vw);
	font-weight: 500;
}

.navbar .text-field {
	margin: 2rem;
	width: 100%;
	height: 4.5rem;
	font-size: calc(1.2rem + 0.9vw);
	font-weight: 500;
}

.navbar-cta {
	padding: 2rem;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	grid-gap: 2rem;
	gap: 2rem;
	max-width: 100%;
}

.navbar-cta svg {
	display: flex;
	justify-content: center;
}

.navbar-cta img {
	flex-shrink: 0;
}

.navbar-cta > *:hover {
	-webkit-transform: translateY(-2px);
	        transform: translateY(-2px);
	cursor: pointer;
	color: var(--colour-primary);
}

.navbar-cta > *:hover:last-child {
	-webkit-transform: none;
	        transform: none;
	cursor: pointer;
	color: var(--colour-primary);
}

.navbar-cta .badge-holder img {
	flex-shrink: 0;
}

.header-icon {
	color: var(--colour-text-white);
}

.header-icon:hover {
	cursor: pointer;
	color: var(--colour-primary);
}

.profile-modal-holder {
	position: relative;
	flex-shrink: 0;
}

.profile-modal {
	display: none;
	position: absolute;
	width: 15rem;
	padding: 1rem;
	right: 0;
	background-color: var(--colour-accent-third);
	margin-top: 1rem;
	z-index: var(--z-index-profile-modal-level);
	color: var(--colour-text-white-two);
	border-radius: 1rem;
	transition: all 0.3s ease-in-out;
}

.profile-modal::before {
	content: '';
	position: absolute;
	top: -1.8rem;
	right: 1rem;
	margin-left: -5px;
	border-width: 10px;
	border-style: solid;
	border-color: transparent transparent var(--colour-accent-third) transparent;
}

.profile-modal > * {
	margin: 5px;
	border-radius: 5px;
	transition: all 0.3s ease-in-out;
}

.profile-modal li {
	color: var(--both-theme-white);
}

.profile-modal li:hover {
	padding-left: 5px;
	background-color: var(--colour-accent-third-with-opacity);
}

.profile-modal-holder:hover .profile-modal {
	display: block;
}

.navbar-mobile {
	width: 100%;
	flex-direction: row;
	transition: all 0.3s ease-in-out;
	grid-area: navbar;
}

@media screen and (max-width: 768px) {
	.navbar-mobile {
		width: 100%;
		display: flex;
		flex-direction: column;
		transition: all 0.3s ease-in-out;
	}
	.navbar-mobile .text-field {
		display: none;
	}
	.navbar-mobile .mobile-searchbar {
		display: block;
	}
	.navbar-mobile .navbar-cta .badge-holder {
		display: none;
	}

	/* !TODO Commented to fix an issue */
	/* .navbar-mobile .profile-modal > *:hover > *:parent {
		display: none;
	} */
}

@media screen and (min-width: 768px) {
	.navbar-mobile .text-field {
		display: block;
	}
	.navbar-mobile .mobile-searchbar {
		display: none;
	}
	.navbar-mobile .navbar-cta .badge-holder {
		display: block;
	}
}

.home-iframe {
	width: 100%;
	height: 100%;
	min-height: 720px;
}

.home-body {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.home-category-section {
	margin: 4rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.categories-listing {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 2rem;
}

.category-card {
	position: relative;
	height: 30rem;
	min-width: 30rem;
	max-width: 60rem;
	flex: 1 1 auto;
	border-radius: 1rem;
	margin: 2rem;
	background-color: var(--colour-black-fifth);
	box-shadow: 2px 2px 5px var(--colour-dark-with-opacity);
	transition: all 0.3s ease;
	overflow: hidden;
	cursor: pointer;
}

.category-card img {
	position: absolute;
	border-radius: 1rem;
	top: 0;
	left: 0;
	width: 60rem;
	height: 40rem;
	object-fit: cover;
	transition: all 0.3s ease;
	z-index: var(--z-index-card-level-1);
}

.category-card:hover img {
	top: 5rem;
	transition: all 0.3s ease;
}

.category-card h3 {
	position: absolute;
	top: 10rem;
	opacity: 0.5;
	transition: all 0.3s ease;
}

.category-card:hover h3 {
	top: 1rem;
	opacity: 1;
	transition: all 0.3s ease;
}

.category-card .btn {
	position: absolute;
	bottom: 48rem;
	left: 0;
	z-index: var(--z-index-card-level-2);
	width: 100%;
	transition: all 0.3s ease;
}
.category-card:hover .btn {
	bottom: -5px;
	transition: all 0.3s ease;
}

@media screen and (max-width: 768px) {
	.home-iframe {
		width: 100%;
		height: 60%;
		min-height: 320px;
	}

	.category-card img {
		height: 30rem;
		min-width: 100%;
		object-fit: cover;
		vertical-align: bottom;
	}

	.category-card h3 {
		position: absolute;
		top: 0;
		width: 100%;
		display: flex;
		justify-content: center;
		border-radius: 1rem;
		opacity: 1;
		z-index: var(--z-index-card-level-2);
		background-color: var(--colour-black-fifth);
		padding: 1rem;
	}

	.category-card .btn {
		position: absolute;
		bottom: 0;
	}

	.category-card:hover .btn {
		bottom: 1rem;
	}
}

.page-not-found {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.page-not-found h1 {
	text-align: center;
}

.page-not-found > * {
	margin: 2rem;
}

.text-404 {
	font-size: 15rem;
}

.category-section {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: var(--z-index-low-level-2);
	background-color: var(--colour-black-one);
	padding: 2rem;
}

.category-items {
	max-width: 90rem;
	margin: 0 auto;
	display: grid;
	grid-template-columns: calc(11.1rem + 2vw) repeat(1, minmax(calc(15.1rem + 2vw), -webkit-max-content));
	grid-template-columns: calc(11.1rem + 2vw) repeat(1, minmax(calc(15.1rem + 2vw), max-content));
	grid-template-rows: 1fr;
	overflow-x: auto;
	align-items: center;
}

.btn-category {
	margin: 1rem 0;
	width: 90%;
	transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 962px) {
	.category-items {
		max-width: 90vw;
	}
}



.single-playlist > hr {
	margin: 2rem 0;
	width: 100%;
}

.single-playlist .videos-hidden {
	-webkit-transform: scaleY(0.5);
	        transform: scaleY(0.5);
	display: none;
}

.single-playlist .videos-show {
	-webkit-transform: scaleY(1);
	        transform: scaleY(1);
	display: flex;
}

.playlist-heading {
	font-size: 2.5rem;
	display: flex;
	align-items: center;
}

.playlist-heading span {
	width: 100%;
	cursor: pointer;
}

.playlist-heading .playlist-delete-icon {
	cursor: pointer;
	color: var(--colour-error);
}

.playlist-heading .playlist-delete-icon:hover {
	background-color: var(--colour-error-second);
	border-radius: 50%;
}

.watch-later-page .video-listing-section {
	margin-top: 2rem;
}

.modal-card {
	min-height: unset;
	width: 100%;
	max-width: 40rem;
	margin: 0 auto;
	border-radius: 5px;
	max-height: 90vh;
	overflow-x: auto;
	padding: 1rem;
}

.input-group > * {
	transition: all 0.3s ease-in-out;
}

.modal-error {
	color: var(--colour-error);
	font-size: 1.2rem;
	font-weight: bold;
	margin: 0.5rem 0;
	width: 25rem;
	line-height: 1.5rem;
	text-align: center;
}
.modal-button {
	margin: 1rem 0;
	width: 90%;
	transition: all 0.3s ease-in-out;
}

.modal-btn-disabled {
	background-color: var(--colour-text-grey);
	color: var(--colour-error-second);
	cursor: not-allowed;
}

.modal-opened {
	-webkit-backdrop-filter: blur(5px);
	        backdrop-filter: blur(5px);
	/* This value is not in var as this is being used only once here */
	background-color: rgb(0 0 0 / 70%);
}

.input-group .text-field {
	margin: 8px 0;
	width: 30rem;
}

.modal-card .btn {
	margin: 0.5rem 0;
}

@media screen and (max-width: 768px) {
	.modal-card {
		max-width: 80vw;
	}

	.input-group .text-field {
		margin: 8px 0;
		width: 69vw;
	}
}

.create-playlist-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 20px;
}
.create-playlist-form p {
	color: var(--colour-error);
	font-weight: bold;
}
.modal-playlist-container {
	width: 30ch;
	padding: 5px;
	max-height: 30rem;
	overflow-y: auto;
}

.modal-single-playlist-pills {
	border: 1px solid var(--colour-secondary);
	cursor: pointer;
	border-radius: 5px;
	margin: 1rem 0;
	padding: 1rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.modal-playlist-container .modal-single-playlist-pills-active {
	color: var(--colour-accent-one);
	border-color: var(--colour-accent-one);
}

.video-page-body {
	min-height: 100%;
	max-width: 80%;
	width: 100%;
	display: grid;
	grid-template-columns: 80% 20%;
	grid-template-areas: 'video-section suggestion-section';
	margin: 0 auto;
}
.video-section {
	grid-area: video-section;
}

.suggestion-section {
	grid-area: suggestion-section;
	max-height: 87vh;
	height: 100%;
	margin: 0 auto;
	overflow-y: auto;
}

.video-player {
	height: 35vw;
	margin: 0 2rem 2rem 0;
}

.video-player iframe {
	height: 100%;
	width: 100%;
}

.video-details {
	margin-right: 2rem;
}

.video-description {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
}

.video-description > * {
	margin: 0.5rem 0;
}

.video-title {
	font-size: 2rem;
	font-weight: bold;
}

.video-cta {
	display: flex;
	justify-content: space-between;
}

.cta-btn {
	display: flex;
	align-items: center;
	margin-right: 1rem;
	cursor: pointer;
}

.cta-btn:hover {
	color: var(--colour-primary);
}
.cta-btn-selected {
	color: var(--colour-primary);
}
@media screen and (min-width: 2019px) {
	.video-page-body {
		max-width: 80%;
	}

	.video-player {
		height: 35vw;
	}
}

@media screen and (max-width: 1719px) {
}

@media screen and (max-width: 1375px) {
	.video-page-body {
		height: 100%;
		grid-template-rows: 1fr 50rem;
		grid-template-columns: 1fr;
		grid-template-areas:
			'video-section'
			'suggestion-section';
	}

	.video-player {
		height: 50vh;
	}

	.suggestion-section {
		grid-area: suggestion-section;
		min-height: 40rem;
		margin: 1rem auto;
		overflow-y: auto;
	}

	.video-details {
		margin: 0;
	}
}

@media screen and (max-width: 1031px) {
	.video-page-body {
		height: 100%;
		grid-template-rows: 1fr 50rem;
		grid-template-columns: 1fr;
		grid-template-areas:
			'video-section'
			'suggestion-section';
	}

	.video-player {
		height: 40vh;
		margin: 0;
	}

	.video-cta {
		flex-wrap: wrap;
		width: 100%;
	}
}

@media screen and (max-width: 768px) {
	.video-page-body {
		max-width: 90%;
		height: 100%;
		grid-template-rows: 1fr 40rem;
		grid-template-columns: 1fr;
		grid-template-areas:
			'video-section'
			'suggestion-section';
	}

	.video-player {
		overflow: hidden;
		position: relative;
		height: 40vh;
	}
}

