.page-not-found {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.page-not-found h1 {
	text-align: center;
}

.page-not-found > * {
	margin: 2rem;
}

.text-404 {
	font-size: 15rem;
}
