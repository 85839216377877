.single-playlist > hr {
	margin: 2rem 0;
	width: 100%;
}

.single-playlist .videos-hidden {
	transform: scaleY(0.5);
	display: none;
}

.single-playlist .videos-show {
	transform: scaleY(1);
	display: flex;
}

.playlist-heading {
	font-size: 2.5rem;
	display: flex;
	align-items: center;
}

.playlist-heading span {
	width: 100%;
	cursor: pointer;
}

.playlist-heading .playlist-delete-icon {
	cursor: pointer;
	color: var(--colour-error);
}

.playlist-heading .playlist-delete-icon:hover {
	background-color: var(--colour-error-second);
	border-radius: 50%;
}
