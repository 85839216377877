.video-listing-section {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin: 2rem auto 0 auto;
	max-width: 1560px;
}

.video-card {
	position: relative;
	overflow: hidden;
	margin: 0 2rem 2rem 0;
	min-width: 24rem;
	max-width: 29rem;
	background-color: var(--colour-black-fifth);
	border-radius: 5px;
	cursor: pointer;
	box-shadow: 0 2px 5px var(--colour-dark-with-opacity);
	perspective: 400px;
	opacity: 0;
	transform: scale(0.5);
	animation-name: card-animation;
	animation-duration: 350ms;
	animation-fill-mode: forwards;
}

@keyframes card-animation {
	0% {
		opacity: 0;
		transform: scale(0.8);
	}
	50% {
		opacity: 0.8;
		transform: scale(1.04);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

.video-card:hover {
	box-shadow: 0 5px 1rem var(--colour-dark-with-opacity);
}

.video-card .video-card-watch-later {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	right: 0;
	padding: 5px;
	background-color: var(--colour-black-fifth);
	color: var(--colour-text-white);
	transform-origin: right;
	transform: rotateY(-120deg);
	transition: 200ms transform ease-in-out;
}

.video-card:hover .video-card-watch-later {
	transform: rotateY(0deg);
}

.video-card-watch-later:hover {
	color: var(--colour-accent-one);
}
.video-card .video-card-in-watch-later {
	transform: rotateY(0deg);
	color: var(--colour-accent-one);
}

.video-card .video-card-add-to-playlist {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 3rem;
	right: 0;
	padding: 5px;
	background-color: var(--colour-black-fifth);
	color: var(--colour-text-white);
	transform-origin: right;
	transform: rotateY(-120deg);
	transition: 400ms transform ease-in-out;
}

.video-card:hover .video-card-add-to-playlist {
	transform: rotateY(0deg);
}

.video-card-add-to-playlist:hover {
	color: var(--colour-accent-one);
}

.video-card .video-card-in-playlist {
	transform: rotateY(0deg);
	color: var(--colour-accent-one);
}

.video-card .video-card-delete-button {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 1rem 0 0 1rem;
	top: 12rem;
	right: 0;
	padding: 5px;
	background-color: var(--colour-error);
	color: var(--both-theme-white);
	transform-origin: right;
	transform: rotateY(-120deg);
	transition: 300ms transform ease-in-out;
}

.video-card:hover .video-card-delete-button {
	transform: rotateY(0deg);
}

.video-card-title {
	font-size: 2rem;
	text-align: center;
	font-weight: bold;
	line-height: 1.5;
	margin: 1rem 1rem 1rem 5px;
	text-overflow: ellipsis;
	text-align: left;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	white-space: normal;
}

.video-card-creator {
	margin: 0 0 0 1rem;
}

.video-card-podcast {
	margin: 0 1rem 0 0;
}

.video-card img {
	margin: 0 4rem 0 0;
	width: 31rem;
	height: 16rem;
	object-fit: cover;
}

@media screen and (max-width: 1719px) {
	.video-listing-section {
		max-width: 1240px;
	}
}

@media screen and (max-width: 1375px) {
	.video-listing-section {
		max-width: 940px;
	}
}

@media screen and (max-width: 1031px) {
	.video-listing-section {
		max-width: 620px;
	}
}

@media screen and (max-width: 768px) {
	.video-listing-section {
		justify-content: center;
	}

	.video-card {
		margin: 2rem;
		max-width: 30rem;
		padding: 0;
	}

	.video-card .video-card-watch-later {
		transform: rotateY(0deg);
		min-height: 4rem;
		min-width: 4rem;
	}

	.video-card .video-card-add-to-playlist {
		transform: rotateY(0deg);
		min-height: 4rem;
		min-width: 4rem;
	}

	.video-card .video-card-delete-button {
		transform: rotateY(0deg);
		min-height: 4rem;
		min-width: 4rem;
		top: 11rem;
	}
}
