.home-iframe {
	width: 100%;
	height: 100%;
	min-height: 720px;
}

.home-body {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.home-category-section {
	margin: 4rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.categories-listing {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 2rem;
}

.category-card {
	position: relative;
	height: 30rem;
	min-width: 30rem;
	max-width: 60rem;
	flex: 1 1 auto;
	border-radius: 1rem;
	margin: 2rem;
	background-color: var(--colour-black-fifth);
	box-shadow: 2px 2px 5px var(--colour-dark-with-opacity);
	transition: all 0.3s ease;
	overflow: hidden;
	cursor: pointer;
}

.category-card img {
	position: absolute;
	border-radius: 1rem;
	top: 0;
	left: 0;
	width: 60rem;
	height: 40rem;
	object-fit: cover;
	transition: all 0.3s ease;
	z-index: var(--z-index-card-level-1);
}

.category-card:hover img {
	top: 5rem;
	transition: all 0.3s ease;
}

.category-card h3 {
	position: absolute;
	top: 10rem;
	opacity: 0.5;
	transition: all 0.3s ease;
}

.category-card:hover h3 {
	top: 1rem;
	opacity: 1;
	transition: all 0.3s ease;
}

.category-card .btn {
	position: absolute;
	bottom: 48rem;
	left: 0;
	z-index: var(--z-index-card-level-2);
	width: 100%;
	transition: all 0.3s ease;
}
.category-card:hover .btn {
	bottom: -5px;
	transition: all 0.3s ease;
}

@media screen and (max-width: 768px) {
	.home-iframe {
		width: 100%;
		height: 60%;
		min-height: 320px;
	}

	.category-card img {
		height: 30rem;
		min-width: 100%;
		object-fit: cover;
		vertical-align: bottom;
	}

	.category-card h3 {
		position: absolute;
		top: 0;
		width: 100%;
		display: flex;
		justify-content: center;
		border-radius: 1rem;
		opacity: 1;
		z-index: var(--z-index-card-level-2);
		background-color: var(--colour-black-fifth);
		padding: 1rem;
	}

	.category-card .btn {
		position: absolute;
		bottom: 0;
	}

	.category-card:hover .btn {
		bottom: 1rem;
	}
}
